<template>
    <div class="content">
        <body>
    <div class="title">支付协议&amp;盲盒规则</div>
    <div class="z_title">特别声明：所有盲盒商品禁止未成年人参与购买！</div>
    <div class="rule">
        <div class="m-2 item_title">【支付协议】</div>
        <div class="m-1">
            支付协议（以下简称“本协议”）适用于所有使用支付的用户（以下或称“您”）。本协议一经同意并接受，即形成您与万能盒平台间具有法律约束力之文件。您确认，您已详细阅读了本协议所有内容，您充分理解并同意接受本协议的全部内容。万能盒平台还需要提醒您的是，如本协议内容发生修改或更新，万能盒平台将会在支付充值面板提示修改内容并公布。您在使用万能盒平台提供的各项服务之前，应仔细阅读本协议，如您不同意变更后的协议内容，应立即停用支付服务。
        </div>
        <div class="m-1">
            您使用支付时，可能会因为您使用的软件版本、设备、操作系统等不同以及第三方原因造成差别，由此可能给您带来的不便，您表示理解，并不会因此向万能盒平台提出任何主张或追究万能盒平台任何责任。
        </div>
        <div class="m-1">
            如果您为未成年人，请在法定监护人的陪同下阅读和判断是否同意本协议，未满18岁需在法定监护人的陪同下才能在万能盒平台购买虚拟产品和增值服务。
        </div>
        <div class="m-2 item_title">支付使用规则</div>
        <div class="m-1"> 
            1. 支付是万能盒平台为您提供的用于在万能盒平台上进行消费的服务，只可用于购买万能盒平台上的各种虚拟产品和增值服务。 支付和人民币的兑换比例是 1:1。
        </div>
        <div class="m-1"> 
            2. 支付使用的货币为人民币。您仅可为自己进行支付，可供支付的方式包括但不限于：支付宝、微信等（具体充值方式仍以万能盒平台实际展示为准）。您知晓并确认，若使用非万能盒平台所指定的方式或渠道进行支付，或以非法的方式进行支付而造成用户权益受损时，不得因此要求万能盒平台作任何补偿或赔偿。
        </div>
        <div class="m-1"> 
            3. 用户必须为使用的支付渠道账户开户人/持有人，可合法、有效使用该账户且未侵犯任何第三方权益，否则因此造成支付账户实际所有人损失的，应由用户单独负责解决由此产生的纠纷并承担全部法律责任。
        </div>
        <div class="m-1"> 
            4. 您知晓并承诺，在使用支付时不进行如下行为：
        </div>
        <div class="m-1"> 
            （1）以营利、经营等非个人使用目的支付；
        </div>
        <div class="m-1"> 
            （2）以非万能盒平台指定的方式或渠道支付；
        </div>
        <div class="m-1"> 
            （3）通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式支付；
        </div>
        <div class="m-1"> 
            （4）通过侵犯万能盒平台或他人合法权益的方式支付；
        </div>
        <div class="m-1"> 
            （5）利用万能盒平台现有或潜在的软件或规则漏洞或与万能盒平台合作的或关联的第三方产品（如支付渠道等）漏洞支付或利用系统BUG或外挂获得以及其他非正当方式来源支付；
        </div>
        <div class="m-1"> 
            （6）通过任何不正当手段或以违反诚实信用原则的方式支付；
        </div>
        <div class="m-1"> 
            （7）通过任何其他违反相关法律、行政法规、国家政策的方式支付；
        </div>
        <div class="m-1"> 
            5. 您知晓并确认，用户一旦支付成功，即确定完成支付。万能盒平台不提供退还或逆向兑换服务。
        </div>
        <div class="m-1"> 
            6. 您知晓并确认，在使用支付系统时，您必须仔细确认支付时的万能盒账号，若因为您自身未登录账号、输入账号错误、操作不当或不了解支付方式等个人因素造成当前游客账号登出、支付账号错误、支付金额错误等情形而损害自身权益，由此带来的损失由用户自行承担。
        </div>
        <div class="m-1"> 
            7. 您可随时在手机APP上查看您的账户支付记录。如您对该记录有异议，应立即向万能盒平台提出，万能盒平台核对后确有错误的，将予以改正；否则您同意万能盒平台上的支付记录作为支付支付和消费的唯一有效依据。
        </div>
        <div class="m-1"> 
            8. 您知晓并确认，如您违反本协议任何条款，万能盒平台均有权不经您事先同意而对您或您的账号进行如下处理：
        </div>
        <div class="m-1"> 
            （1）对您账号的支付充值和消费功能进行临时冻结，冻结期限由万能盒平台视情况决定；
        </div>
        <div class="m-1"> 
            （2）对您的账号进行永久封禁；
        </div>
        <div class="m-1"> 
            （3）对您账号（或有证据证明与您关联或由您控制的其他账号）中的支付余额或其他虚拟产品进行全部或部分扣除；
        </div>
        <div class="m-1"> 
            （4）中止或终止向您的账号（或有证据证明与您关联或由您控制的其他账号）提供增值服务；
        </div>
        <div class="m-1"> 
            （5）要求您以法定货币等价返还已经消费的、以非正常方式充值的支付（包括以充电、打赏、赠予等方式已经消费掉的支付）；
        </div>
        <div class="m-1"> 
            （6）要求您进一步赔偿给万能盒平台造成的损失；
        </div>
        <div class="m-1"> 
            （7）若您的行为已涉及违法犯罪，则万能盒平台有权移交司法机关处理。
        </div>
        <div class="m-1"> 
            万能盒平台有权依据您行为的性质和严重程度决定对您或您的账号采取上述一种或多种处理方式。在您的行为给万能盒平台造成的影响或损失未消除或未得到弥补前，万能盒平台有权决定对您或您的账号持续采取上述处理方式。如您对上述处理方式不服或有疑问，您可以联系客服进行申诉，但您需要提供客观、真实、有效的证据，包括但不限于支付记录、消费记录等。
        </div>

        <div class="m-1"> 
            9. 您知晓并确认，当您的账户遇到争议时（例如挂失或重置密码），我们有权根据您的注册和绑定信息对您的身份进行识别。但在目前的技术水平下，我们所能采取的方法有限，且在网络上进行用户身份识别存在一定的困难，因此，我们对鉴定用户身份的准确性和绝对真实性不做任何保证。
        </div>
        <div class="m-1"> 
            10. 您可以凭注册信息及注册联系方式联系客服挂失冻结账户，但如因您本人的挂失时间延迟，而导致此期间内发生充值损失，由此带来的损失由用户自行承担
        </div>
        <div class="m-1"> 
            11. 您知晓并确认，盲盒可以选择“一发必中”或“五发连抽”，新人礼盒支持“欧皇三连”，盲盒商品存在概率性：
        </div>
        <div class="m-1"> 
            （1）盲盒的商品产出概率为：普通款、稀有款、史诗款、至尊款,此概率针对全平台用户，用户每次开盒概率单独计算，不累计；
        </div>
        <div class="m-1"> 
            （2）商品概率以盲盒详情页概率为准；
        </div>
        

        <div class="m-1"> 
            免责事由
        </div>
        <div class="m-1"> 
            12. 您知悉并同意，万能盒平台不因下述任一情况而可能导致的任何损害赔偿承担责任，包括但不限于财产、收益、数据资料等方面的损失或其它无形损失：
        </div>
        <div class="m-1"> 
            （1）因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素导致支付系统或服务不能正常运作；
        </div>
        <div class="m-1"> 
            （2）由于黑客攻击、电信部门技术调整或故障、系统维护等原因而造成的系统服务中断或者延迟；
        </div>
        <div class="m-1"> 
            （3）由于政府命令、法律法规的变更、司法机关及行政机关的命令、裁定等原因而导致的系统服务中断、终止或延迟；
        </div>
        <div class="m-1"> 
            （4）由于您将账户密码告知他人或未保管好自己的密码或与他人共享账户或任何其他非万能盒平台的过错，导致您的个人资料泄露；
        </div>
        <div class="m-1"> 
            （5）由于与万能盒平台链接或合作的其它网站（如网上银行等）所造成的银行账户信息、身份信息泄露及由此而导致的任何法律争议和后果；
        </div>
        <div class="m-1"> 
            （6）您（包括未成年人用户）向本公司提供错误、不完整、不实信息等，造成不能使用支付账户或遭受任何其他损失；
        </div>
        <div class="m-1"> 
            （7）如因系统维护或升级的需要而需暂停支付服务时，我们将尽可能事先进行通知。对于支付服务的中断或终止而给您造成的任何损失，我们无须对您或任何第三方承担任何责任。
        </div>
        <div class="m-1"> 
            13. 因您自身的原因导致万能盒平台无法提供支付服务或提供支付服务时发生任何错误而产生的任何损失或责任，由您自行负责，万能盒平台不承担责任，包括但不限于：
        </div>
        <div class="m-1"> 
            （1）您未按照本协议或万能盒平台不时公布的任何规则进行操作；
        </div>
        <div class="m-1"> 
            （2）因您的万能盒账号失效、丢失、被封停；
        </div>
        <div class="m-1"> 
            （3）因您绑定的第三方支付机构账户的原因导致的损失或责任，包括您使用未经认证的第三方支付账户或使用非您本人的第三方支付账户，您的第三方支付账户被冻结、查封等；
        </div>
        <div class="m-1"> 
            （4）因您个人的故意或重大过失所造成的财产损失。
        </div>
        <div class="m-2 item_title">违约责任</div>

        <div class="m-1"> 
            如您违反本协议项下的任何规则、声明、承诺及保证，我们保留根据实际损失向您追究责任的权利。
        </div>
        <div class="m-1"> 
            本协议的订立、效力、解释、履行、修改和终止以及争议的解决适用中华人民共和国法律。因解释和履行本协议而发生的任何争议，本协议双方应首先通过友好协商的方式加以解决。如果在一方向其他方发出要求协商解决的书面通知后 30天之内争议仍然得不到解决，则应将争议提交仲裁委员会按照其仲裁规则进行仲裁。仲裁裁决为一裁终局，对双方均有法律约束力。
        </div>
        <div class="m-1"> 
            其他
        </div>
        <div class="m-1"> 
            1. 本协议项下所有的通知均可通过重要页面公告、平台站内信等方式进行，该等通知于发送之日视为已送达收件人。
        </div>
        <div class="m-1"> 
            2. 本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的，未赋予本协议各方其他权利。
        </div>
        <div class="m-1"> 
            3. 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
        </div>
        <div class="m-1"> 
            4. 本协议中的标题仅为方便而设，不具法律或契约效果。
        </div>
        <div class="m-2 item_title">盲盒规则</div>

        <div class="m-1"> 
            温馨提示：
        </div>
        <div class="m-1"> 
            18岁以下未成年人禁止购买！“万能盒”消费，具有随机性的特点，由万能盒商家发货，不支持7天无理由退换。 盲盒商品存在概率性，付费请谨慎。 商品可使用免邮券免费发货，开出不喜欢的商品可按照盲盒支付价格的80％(普通盲盒)进行回收并存入金币余额，购买时默认优先使用金币。 金币不支持提现。关于普通盲盒概率,该概率为综合概率，普通款(N)为38%，稀有款(R)为50%，史诗款(SR)为11.98%，至尊款(SSR)为0.02%，普通盲盒产出商品会在实时广播内公示。
        </div>
        <div class="m-1"> 
            苹果公司没有以任何方式参与竞赛或盲盒抽奖!
        </div>
        <div class="m-1"> 
            盲盒奖品非苹果公司赞助!
        </div>
        <div class="m-1"> 
            1.购买普通盲盒数量时可使用“一发必中”和“五发不重”，支付盲盒商品售价的全款金额后即可开启盲盒。
        </div>
        <div class="m-1"> 
            2.扣款成功后，生成订单并开启盲盒，获得的盲盒商品会在“我的商品”中等待发货。您可前往发货页面进行发货操作。平台按照您自行填写的收货地址发货，快递一经签收即视为该商品交付给您。
        </div>
        <div class="m-1"> 
            3.因盲盒商品的随机性，可能存在商品重复出现的情况。鉴于盲盒商品的随机性和特殊性，不支持7天无理由退换货。如因您不喜欢获得的商品或商品重复，可以在“我的商品-领取商品”中选中相应的商品进行置换，置换金额为所购买盲盒总支付金额的80%(普通盲盒)金币。
        </div>
        <div class="m-1"> 
            4.1金币=1人民币，金币不可提现，金币在支付过程中优先代替人民币进行抵扣。
        </div>
        <div class="m-1"> 
            5. 由于平台内所有的商品均为商家提供上架，为防止因商品缺货导致快递无法发出，故请在获得商品（包含保险箱）后，30天内申请发货，若在30天内未申请发货，到期后该商品将被平台自动寄售回收，寄售回收金额为平台默认回收金额，将以金币形式自动转入至您的个人账户金币余额中。
        </div>
        <div class="m-2 item_title">【关于配送】</div>

        <div class="m-1"> 
            1.盲盒商品可配送区域为中国大陆地区(除特殊偏远地区和港澳台地区)，收件地址在此之外的区域请勿下单。
        </div>
        <div class="m-1"> 
            2.盲盒商品将使用顺丰、京东、申通、韵达、圆通等快递公司邮寄,运费为12元/件，部分偏远地区可能为其他合作物流公司。
        </div>
        <div class="m-1"> 
            3.若申请发货后，因商品处于无货状态、部分偏远地区未能发货等因素拒绝发货后，请及时联系客服处理。
        </div>
        <div class="m-1"> 
            4. 在向快递签收商品时，请您亲自检查包装情况，在快递面前验货，并在确认无误后签收。
        </div>
        <div class="m-1"> 
            5. 受疫情及其他不可抗力因素影响，多地物流公司无法接单及派送，订单将暂缓发出，待当地情况好转后处理。
        </div>
        <div class="m-2 item_title">【售后问题】</div>

        <div class="m-1"> 
            1.盲盒商品不支持7天无理由退换货；
        </div>
        <div class="m-1"> 
            2.盲盒商品付款完成后即可查看该订单的商品，因盲盒商品随机出现，可能出现重复商品，如您不喜欢该商品，可回收赏金，并在“赏金商城”中选中相应的商品进行置换。
        </div>
        <div class="m-1"> 
            3.因盲盒商品的随机性，可能存在商品重复出现的情况。鉴于盲盒商品的随机性和特殊性，不支持7天无理由退换货。
        </div>
        <div class="m-1"> 
            4.确认地址：确认发货并支付完运费前，请仔细确认您的邮寄地址，不支持因地址错误而提出的取消订单和退款申请；
        </div>
        <div class="m-1"> 
            5. 盲盒内商品为全新官方渠道采购，发货后不支持因为颜色、尺码、不喜欢等为由提出的退换货申请，请您知悉并谨慎考虑后购买
        </div>
        <div class="m-1"> 
            6. 如您收到的商品有质量问题，如:存在损坏、少件或遗失，请您提供有效凭证，证明签收时商品即存在上述问题，平台将提供退换货服务且由平台承担来回运费。
        </div>
        <div class="m-2 item_title">【免责声明】</div>

        <div class="m-1"> 
            盲盒所有的商品皆购于国内官方正规渠道，显示的图片与文字均为网上搜集，若发现有侵权商品请联系下架删除！
        </div>
    <div class=" m-2">活动最终解释权归万能盒官方所有</div>
      
       
        
    </div>

</body>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
.content{
    font-size: 0.34rem;
    padding: 0.2rem;
    letter-spacing: 0.01rem;
    word-break: break-all;
    p{
        font-size: 0.37rem;
        font-weight: bold;
    }
}
</style>